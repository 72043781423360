import "dayjs/locale/en";
import "dayjs/locale/ru";

import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import moment from "moment";

export { Dayjs } from "dayjs";

dayjs.extend(localeData);

export const dt = dayjs;

export enum FORMAT {
  DEFAULT = "DD.MM.YYYY",
  DATETIME = "DD.MM.YYYY HH:mm",
  TIME = "HH:mm",
}

export const changeLocale = (locale: string) => dayjs.locale(locale);

export const isValid = (date?: string | number): boolean =>
  date ? dayjs(date).isValid() : false;

export const format = (
  date?: string,
  format: FORMAT = FORMAT.DEFAULT
): string | null => (isValid(date) ? dayjs(date).format(format) : null);

export const currentYear = new Date().getFullYear();

export const getStartDate = () => {
  let currentDay = moment.utc().isoWeekday();
  let initialDate;

  if ([2, 4, 6].includes(currentDay)) {
    initialDate = moment.utc().add(6, "days");
  } else if ([1, 3, 5].includes(currentDay)) {
    initialDate = moment.utc().add(7, "days");
  } else if (currentDay === 7) {
    initialDate = moment.utc().add(5, "days");
  } else {
    initialDate = moment.utc().add(3, "days");
  }

  initialDate.hours(0).minutes(0).seconds(0).milliseconds(0);

  return initialDate.toISOString();
};

export function formatDate(date?: string | Date, toISO = false) {
  if (!date) {
    return "";
  }

  const parsedDate: any = parseDate(date);

  let dd = parsedDate.getDate();
  let mm = parsedDate.getMonth() + 1; // january is 0!

  const yyyy = parsedDate.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return toISO ? yyyy + "-" + mm + "-" + dd : dd + "." + mm + "." + yyyy;
}

export function parseDate(date: string | Date): Date {
  if (!date) {
    return new Date();
  }

  if (date instanceof Date) {
    return date;
  }

  const ISO = /\d{4}-\d{1,2}-\d{1,2}/;
  const DMY = /\d{1,2}\.\d{1,2}\.\d{4}/;
  const MDY = /\d{1,2}\/\d{1,2}\/\d{4}/;
  let dateParts;
  let yyyy;
  let mm;
  let dd;

  if (ISO.test(date)) {
    dateParts = date.split("-");
    yyyy = dateParts[0];
    mm = dateParts[1];
    dd = dateParts[2];
  } else if (DMY.test(date)) {
    dateParts = date.split(".");
    yyyy = dateParts[2];
    mm = dateParts[1];
    dd = dateParts[0];
  } else if (MDY.test(date)) {
    dateParts = date.split("/");
    yyyy = dateParts[2];
    mm = dateParts[0];
    dd = dateParts[1];
  } else {
    throw "Invalid date format";
  }

  return new Date(Date.UTC(Number(yyyy), Number(mm) - 1, Number(dd)));
}

export const createMaxDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + 1);
  return newDate;
};
export const createMinDate = (weekLength: boolean) => {
  return getStartDay(weekLength);
};

// const getStartDay = (weekLength: boolean) => {
//   const today = moment.utc();
//   const dayOfWeek = today.isoWeekday(); // Monday = 1, Sunday = 7
//   let initial:any;
//   const jan3 = moment.utc("2025-01-03");

//     if (weekLength) {
//       // Four-day week logic
//       if (dayOfWeek === 2 || dayOfWeek === 3 || dayOfWeek === 4) {
//         initial = today.add((1 + 7 - dayOfWeek) % 7, "days").startOf("day");
//       } else if (dayOfWeek === 1) {
//         initial = today.add(7, "days").startOf("day");
//       } else {
//         initial = today.add((3 + 7 - dayOfWeek) % 7, "days").startOf("day");
//       }
//     } else {
//       // Regular week logic
//       if (dayOfWeek === 1 || dayOfWeek === 2) {
//         initial = today.add(5 - dayOfWeek, "days").startOf("day");
//       } else if (dayOfWeek === 3 || dayOfWeek === 4) {
//         initial = today.add((1 + 7 - dayOfWeek) % 7, "days").startOf("day");
//       } else {
//         initial = today.add((3 + 7 - dayOfWeek) % 7, "days").startOf("day");
//       }
//     }

//     while (
//       initial.isBefore(jan3) ||
//       disabledDates.some((date) => initial.isSame(date, "day"))
//     ) {
//       initial.add(1, "day");
//     }

//   return initial.toDate();
// };

const getStartDay = (weekLength:boolean) => {
  const today = moment.utc();
  const dayOfWeek = today.isoWeekday(); // Monday = 1, Sunday = 7
  let initial;

  if (weekLength) {
    // Four-day week logic
    if (dayOfWeek === 2 || dayOfWeek === 3 || dayOfWeek === 4) {
      initial = today.add((1 + 7 - dayOfWeek) % 7, "days").startOf("day");
    } else if (dayOfWeek === 1) {
      initial = today.add(7, "days").startOf("day");
    } else {
      initial = today.add((3 + 7 - dayOfWeek) % 7, "days").startOf("day");
    }
  } else {
    // Regular week logic
    if (dayOfWeek === 1 || dayOfWeek === 2) {
      initial = today.add(5 - dayOfWeek, "days").startOf("day");
    } else if (dayOfWeek === 3 || dayOfWeek === 4) {
      initial = today.add((1 + 7 - dayOfWeek) % 7, "days").startOf("day");
    } else {
      initial = today.add((3 + 7 - dayOfWeek) % 7, "days").startOf("day");
    }
  }

  return initial.toDate();
};

const disabledDates = [
  new Date("2024-12-23"),
  new Date("2024-12-25"),
  new Date("2024-12-27"),
  new Date("2024-12-30"),
  new Date("2025-01-01"),

]

const additionalDisabledDatesForNewCity = [
  new Date('2025-01-13'),
  new Date('2025-01-15'),
];


export const dateFilterCalendarDisabledByDays = (date: Date,  isNewCity: boolean
  ): boolean => {
  const dayOfWeek = date.getDay();

  const isDisabledDay = dayOfWeek === 1 || dayOfWeek === 3 || dayOfWeek === 5;
  return !isDisabledDay || isSpecificDateDisabled(date, isNewCity);
};

export const dateFilterCalendarDisabledByDaysFourWeek = (
  date: Date,
  isNewCity:boolean
): boolean => {
  const dayOfWeek = date.getDay();
  const isDisabledDay = dayOfWeek === 1 || dayOfWeek === 3;
  return !isDisabledDay || isSpecificDateDisabled(date, isNewCity);
};


// // Helper function to check for specific disabled dates
// const isSpecificDateDisabled = (date: Date): boolean => {
//   return disabledDates.some(
//     disabledDate => date.toDateString() === disabledDate.toDateString()
//   );
// };

const isSpecificDateDisabled = (date: Date, isNewCity: boolean): boolean => {
  const additionalDisabledDates = isNewCity ? additionalDisabledDatesForNewCity : [];
  return disabledDates.concat(additionalDisabledDates).some(
    disabledDate => date.toDateString() === disabledDate.toDateString()
  );
};
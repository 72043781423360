import React, { lazy } from "react";

import { DictionaryKey } from "src/i18n";
import { FeScheme, PAGES } from "src/components/routes/types";

export type Roles = "guest";
export const pagesRedirect: Record<Roles, FeScheme> = {
  guest: [{ scheme: PAGES.ROOT }],
};
export type PageRoute = {
  access: "all";
  title?: DictionaryKey;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
};

export const pages: Record<PAGES, PageRoute> = {
  [PAGES.ROOT]: {
    access: "all",
    title: "src.components.routes.pages.OnlineOrder.title",
    component: lazy(() => import("src/components/routes/pages/OnlineOrder")),
  },
  [PAGES.ROOT_EN]: {
    access: "all",
    title: "src.components.routes.pages.OnlineOrder.title",
    component: lazy(() => import("src/components/routes/pages/OnlineOrderEn")),
  },

  [PAGES.REFERAL]: {
    access: "all",
    title: "src.components.routes.pages.Referal.title",
    component: lazy(() => import("src/components/routes/pages/Referal")),
  },
  [PAGES.GIFT_VOUCHER]: {
    access: "all",
    title: "src.components.routes.pages.GiftVoucher.title",
    component: lazy(() => import("src/components/routes/pages/GiftVoucher")),
  },
  [PAGES.DEMO]: {
    access: "all",
    title: "src.components.routes.pages.Root.title",
    component: lazy(() => import("src/components/routes/pages/Demo")),
  },
  [PAGES.DEMO_EN]: {
    access: "all",
    title: "src.components.routes.pages.Root.title",
    component: lazy(() => import("src/components/routes/pages/DemoEn")),
  },
  [PAGES.BACK_BOXES]: {
    access: "all",
    title: "src.components.routes.pages.BackBoxes.title",
    component: lazy(() => import("src/components/routes/pages/BackBoxes")),
  },
  [PAGES.DIET_BRNO]: {
    access: "all",
    title: "src.components.routes.pages.DietBrno.title",
    component: lazy(() => import("src/components/routes/pages/DietBrno")),
  },
  [PAGES.DIET_HRADEC]: {
    access: "all",
    title: "src.components.routes.pages.DietHradec.title",
    component: lazy(() => import("src/components/routes/pages/DietHradec")),
  },
  [PAGES.DIET_HRADEC_KRALOVE]: {
    access: "all",
    title: "src.components.routes.pages.DietHradec.title",
    component: lazy(() => import("src/components/routes/pages/DietHradecKralove")),
  },
  [PAGES.DIET_JABLONEC]: {
    access: "all",
    title: "src.components.routes.pages.DietJablonec.title",
    component: lazy(() => import("src/components/routes/pages/DietJablonec")),
  },
  [PAGES.DIET_JABLONEC_NISOU]: {
    access: "all",
    title: "src.components.routes.pages.DietJablonec.title",
    component: lazy(() => import("src/components/routes/pages/DietJablonecNisou")),
  },
  [PAGES.DIET_KLADNO]: {
    access: "all",
    title: "src.components.routes.pages.DietKladno.title",
    component: lazy(() => import("src/components/routes/pages/DietKladno")),
  },
  [PAGES.DIET_SREDNI_CECHI]: {
    access: "all",
    title: "src.components.routes.pages.DietSredniCechi.title",
    component: lazy(() => import("src/components/routes/pages/DietSredniCechi")),
  },
  [PAGES.DIET_LIBEREC]: {
    access: "all",
    title: "src.components.routes.pages.DietLiberec.title",
    component: lazy(() => import("src/components/routes/pages/DietLiberec")),
  },
  [PAGES.DIET_MLDA]: {
    access: "all",
    title: "src.components.routes.pages.DietMlda.title",
    component: lazy(() => import("src/components/routes/pages/DietMlda")),
  },
  [PAGES.DIET_MLDA_BOLESLAV]: {
    access: "all",
    title: "src.components.routes.pages.DietMlda.title",
    component: lazy(() => import("src/components/routes/pages/DietMldaBoleslav")),
  },
  [PAGES.DIET_PARADUBICE]: {
    access: "all",
    title: "src.components.routes.pages.DietParadubice.title",
    component: lazy(() => import("src/components/routes/pages/DietParadubice")),
  },
  [PAGES.DIET_PLZEN]: {
    access: "all",
    title: "src.components.routes.pages.DietPlzen.title",
    component: lazy(() => import("src/components/routes/pages/DietPlzen")),
  },
  [PAGES.DIET_PRAHA]: {
    access: "all",
    title: "src.components.routes.pages.DietPraha.title",
    component: lazy(() => import("src/components/routes/pages/DietPraha")),
  },
  [PAGES.DIET_LABU]: {
    access: "all",
    title: "src.components.routes.pages.DietLabu.title",
    component: lazy(() => import("src/components/routes/pages/DietLabu")),
  },
  [PAGES.DIET_TEPLICE]: {
    access: "all",
    title: "src.components.routes.pages.DietTeplice.title",
    component: lazy(() => import("src/components/routes/pages/DietTeplice")),
  },

  [PAGES.DIET_LITOMERICE]: {
    access: "all",
    title: "src.components.routes.pages.DietLitomerice.title",
    component: lazy(() => import("src/components/routes/pages/DietLitomerice")),
  },
  [PAGES.DIET_ZLIN]: {
    access: "all",
    title: "src.components.routes.pages.DietZlin.title",
    component: lazy(() => import("src/components/routes/pages/DietZlin")),
  },

  [PAGES.GDPR]: {
    access: "all",
    title: "src.components.routes.pages.Gdpr.title",
    component: lazy(() => import("src/components/routes/pages/Gdpr")),
  },
  // [PAGES.SWITCH_BOXES]:{
  //   access: "all",
  //   title: "src.components.routes.pages.SwitchBoxes.title",
  //   component: lazy(() => import("src/components/routes/pages/SwitchBoxes")),
  // },

  // [PAGES.ONLINE_ORDER]: {
  //   access: "all",
  //   title: "src.components.routes.pages.OnlineOrder.title",
  //   component: lazy(() => import("src/components/routes/pages/OnlineOrder")),
  // },
  // [PAGES.ONLINE_ORDER_EN]: {
  //   access: "all",
  //   title: "src.components.routes.pages.OnlineOrder.title",
  //   component: lazy(() => import("src/components/routes/pages/OnlineOrderEn")),
  // },
  [PAGES.TERMS]: {
    access: "all",
    title: "src.components.routes.pages.Terms.title",
    component: lazy(() => import("src/components/routes/pages/Terms")),
  },
  [PAGES.PRAVILA_COOKIES]: {
    access: "all",
    title: "src.components.routes.pages.PravilaCookies.title",
    component: lazy(() => import("src/components/routes/pages/PravilaCookies")),
  },
  [PAGES.ACE_TERMS]: {
    access: "all",
    title: "src.components.routes.pages.AceTerms.title",
    component: lazy(() => import("src/components/routes/pages/AceTerms")),
  },
  [PAGES.THANK_YOU]: {
    access: "all",
    title: "src.components.routes.pages.ThankYou.title",
    component: lazy(() => import("src/components/routes/pages/ThankYou")),
  },
  [PAGES.THANK_YOU_AFFILIATE]: {
    access: "all",
    title: "src.components.routes.pages.ThankYouAffiliate.title",
    component: lazy(
      () => import("src/components/routes/pages/ThankYouAffiliate")
    ),
  },
  [PAGES.PAYMENT_NOT_COMPLATE]: {
    access: "all",
    title: "src.components.routes.pages.PaymentNotCompleted.title",
    component: lazy(
      () => import("src/components/routes/pages/PaymentNotCompleted")
    ),
  },
  [PAGES.THANK_YOU_ORDER]: {
    access: "all",
    title: "src.components.routes.pages.ThankYouOrder.title",
    component: lazy(() => import("src/components/routes/pages/ThankYouOrder")),
  },
  [PAGES.VYZKOUSEJ_MENU]: {
    access: "all",
    title: "src.components.routes.pages.VyzkousejMenu.title",
    component: lazy(() => import("src/components/routes/pages/VyzkousejMenu")),
  },
  [PAGES.NOT_FOUND]: {
    access: "all",
    title: "src.components.routes.pages.NotFound.title",
    component: lazy(() => import("src/components/routes/pages/NotFound")),
  },
};
